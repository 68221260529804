<script>
import TemplateInput from '../../../organisms/TemplateInput';
import TemplateBase from '../TemplateBase';

export default defineNuxtComponent({
  components: { TemplateBase, TemplateInput },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filledValues: [],
      labelsCopy: [],
      optionsInEditState: [],
    };
  },
  computed: {
    optionIndex() {
      return this.filledValues.length + 1;
    },
  },
  mounted() {
    this.filledValues = this.stepElement.values;
    this.labelsCopy = this.stepElement.values.map(value => value.label);
  },
  watch: {
    stepElement() {
      this.filledValues = this.stepElement.values;
      this.labelsCopy = this.stepElement.values.map(value => value.label);
      this.optionsInEditState = [];
    },
  },
  methods: {
    canRemoveOption(option) {
      return (
        this.filledValues.length > 1
          && !this.optionsInEditState.includes(option.value)
      );
    },
    addOptionsInEditState(optionValue) {
      if (!this.optionsInEditState.includes(optionValue))
        this.optionsInEditState.push(optionValue);
    },
    removeOptionsInEditState(optionValue) {
      this.optionsInEditState = this.optionsInEditState.filter(
        option => option !== optionValue,
      );
    },
    updateOption(index) {
      this.labelsCopy[index] = this.filledValues[index].label;
      this.$emit('onElementChanged', this.stepId);
    },
    addSelectionOption() {
      this.labelsCopy.push(`Option ${this.optionIndex}`);

      this.filledValues.push({
        label: `Option ${this.optionIndex}`,
        value: `option-${this.optionIndex}`,
        selected: false,
      });

      this.$emit('onElementChanged', this.stepId);
    },
    removeSelectionOption(index) {
      this.filledValues.splice(index, 1);
      for (const [index, value] of this.filledValues.entries())
        value.value = `option-${index + 1}`;

      this.labelsCopy = this.filledValues.map(field => field.label);
      this.$emit('onElementChanged', this.stepId);
    },
    closeInput(index) {
      this.filledValues[index].label = this.labelsCopy[index];
    },
  },
});
</script>

<template>
  <TemplateBase
    :element="stepElement"
    :placeholder="__('Label dropdown')"
    :step-id="stepId"
  >
    <template #templateContent>
      <div class="flex flex-col space-y-2">
        <trailblazer-stack>
          <div
            v-for="(option, index) in filledValues"
            :key="index"
            class="group/template-input flex h-22 items-center space-x-4 rounded border border-athens-gray px-4 py-4.5"
          >
            <trailblazer-radio :checked="option.selected" />
            <TemplateInput
              :key="index"
              :index="index"
              :option="option"
              @close-input="closeInput"
              @add-options-in-edit-state="addOptionsInEditState"
              @remove-options-in-edit-state="removeOptionsInEditState"
              @update-option="updateOption"
            />
            <div
              v-if="canRemoveOption(option)"
              class="invisible cursor-pointer group-hover/template-input:visible"
              @click="removeSelectionOption(index)"
            >
              <trailblazer-icon name="remove" />
            </div>
          </div>
        </trailblazer-stack>

        <div
          class="flex cursor-pointer rounded border-2 border-dashed border-athens-gray px-4 py-4.5"
          @click="addSelectionOption"
        >
          <trailblazer-icon name="add" />
          {{ __('Add selection option') }}
        </div>
      </div>
    </template>
  </TemplateBase>
</template>
